<template>
    <v-container
        class="fill-height"
        fluid
        dark
        style="background-size: cover"
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                sm="8"
                md="4"
            >
            
            
                <v-col cols="12" class="text-center">
                    <img alt="Login" src="../../assets/images/grupo-inno.jpg" style="width: 250px;">
                </v-col>
                <v-card class="elevation-12">
                    <v-card-text>
                        <v-col cols="12" class="text-center">Inicia sesión en el grupo Innofarm</v-col>
                        <v-form ref="form_login">
                            <v-text-field
                                v-model="user"
                                label="Usuario"
                                prepend-icon="mdi-account"
                                type="text"
                                :rules=requiredRule
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                label="Contraseña"
                                prepend-icon="mdi-lock"
                                :type="show ? 'text' : 'password'"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show = !show"
                                :rules=requiredRule
                            ></v-text-field>
                            <v-select
                                dense
                                v-model="company"
                                :items="companies"
                                prepend-icon="mdi-domain"
                                label="Seleccione una empresa"
                                item-text="name"
                                item-value="tenantId"
                                :rules=requiredRule
                            ></v-select>
                        </v-form>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-col cols="12">
                        <v-btn 
                            color="primary" 
                            block
                            class="px-5"
                            @click="loginUser"
                            :loading=loadingBtn
                        >
                            <v-icon>mdi-login-variant</v-icon> Ingresar
                        </v-btn>
                    </v-col>
                    <v-row class="px-3">
                        <v-col cols="12" class="text-center">
                            <v-btn 
                                small 
                                text 
                                color="primary"
                                link
                                to="/recuperar_contrasena"
                            >
                                <v-icon>mdi-comment-question-outline</v-icon> Olvide mi contraseña
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
    name:'LoginComponent',
    data: () =>({
        show: false,
        user:'',
        password:'',
        company:'',
        requiredRule:[
            v => !!v || 'El campo es requerido'
        ],
    }),
    computed:{
        ...mapState('master',['loadingBtn','companies'])
    },
    methods:{

        ...mapMutations('master',['setTenantId']),

        ...mapActions('access',['login']),
    
        loginUser(){
            
            if(!this.$refs.form_login.validate())
                return
            
            localStorage.setItem('tenantId',this.company)
            this.setTenantId(this.company)

            this.login({
                user:this.user,
                password: this.password,
                company: this.company
            })
            
            
        }
    }
}
</script>

<style>

</style>